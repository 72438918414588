import React from "react";
import { Switch, Redirect, useLocation, Route } from "react-router-dom";

import AppPage from "./pages/app/AppPage";
import DetailsPage from "./pages/home/DetailsPage";
import HomePage from "./pages/home/HomePage";
import NewChecklistPage from "./pages/safe-card/ObservationPage";

export default function Routes() {
  const location = useLocation();
  return (
    <Switch location={location} key={location.pathname}>
      <Redirect exact from="/" to="/app" />
      <Route exact path="/app" component={AppPage}></Route>
      <Route exact path="/home" component={HomePage}></Route>
      <Route exact path="/new-checklist" component={NewChecklistPage}></Route>
      <Route exact path="/details/:id" component={DetailsPage}></Route>
    </Switch>
  );
}
