/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://2hjcdwv3w5eivgidjqn4bk3xga.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:2239e3f6-f36b-4829-86b5-c1e48faada57",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_uA9JtoRDs",
    "aws_user_pools_web_client_id": "7tpmk1kvbobp5f6g30bl7lv45m",
    "oauth": {
        "domain": "tuckerapps-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev.tuckerenergyapps.com/",
        "redirectSignOut": "https://dev.tuckerenergyapps.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "safecardcliente091a0da762d4952898494a55f7a22f495940-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
